<div class="delete-account-container">
  <div class="close-modal" (click)="closeModal()" data-cy="delete-account-close">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_deleteAccount_popup_header' | translate }}</div>
  <div class="description">{{ 'PWA_deleteAccount_popup_body' | translate }}</div>

  <app-button
    data-cy="delete-account-cancel-btn"
    [label]="'PWA_deleteAccount_popup_CTA_Cancel' | translate"
    [uppercase]="false"
    (clicked)="closeModal()" />

  <div data-cy="go-to-delete-account-btn" class="delete-account" (click)="goToAccountDeletion()">
    {{ 'PWA_deleteAccount_popup_CTA_link_yesContinue' | translate }}
    <img src="assets/icon/right-arrow-blue.svg" />
  </div>
</div>
